<style>
i.mw-20{
  min-width: 20px;
}
#resourceForm .form-label{
  font-weight: bold;
}
.custom-control-label{
  font-weight: bold !important;
}

</style>
<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <b-overlay :show="showLoader" no-wrap></b-overlay>
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
          <div class="card-body table-responsive table-head pt-2">
              <div class="row">
                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                  <h1 class="m-0 text-dark pt-2 text-left px-0">Resources</h1>
                </div>
                <div class="col-md-4 bor-bot pr-0">
                  <div class="text-right pb-3 pt-1">
                    <ol class="breadcrumb float-sm-right pr-0">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                    </ol>
                  </div>
                </div>
              </div>
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>

              <div class="row">
                <div class="col-md-6 mt-3 showentries pl-0">
                </div>
                <div class="col-md-6 pr-0">
                  <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                    <div class=" card-text d-flex align-items-center float-right mb-0">
                      <div>
                        <a href="#" @click="addResource()" class="btn btn darkblubtn btn-outline-primary btn-outline-primary">Add Resource</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-0 mt-3 faqsearchsec faqseclisttable">
                  <table class="table table-sm table-bordered table-hover mx-0" style="width:100%;" id="resourceList">
                    <thead class="thead-dark">
                    <tr>
                      <th class="d-none">Id</th>
                      <th width="60%">File Name</th>
                      <th width="10%">Type</th>
                      <th width="15%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <b-modal
                    id="edit-modal"
                    :title="editModal.title"
                    size="lg"
                    ref="modal"
                    @ok.prevent="handleOk(editModal.function)"
                    @hide="resetEditModal"
                >
                  <b-overlay :show="formLoader" no-wrap></b-overlay>
                      <form ref="form" @submit.stop.prevent="handleSubmit" id="resourceForm">
                        <div class="row">
                          <div class="col-md-6">
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >File name<sup class="text-danger">*</sup>
                            <span v-b-tooltip.hover title="Maximum 60 characters"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                          </label
                          >
                          <b-form-input
                              @keydown="resourceErrors.clear('file_name')"
                              v-model="resource.file_name"
                              :class="resourceErrors.has('file_name') ? 'is-invalid' : ''"
                              id="feedback-name"
                              maxlength="60"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!resourceErrors.has('file_name')"
                          >
                            {{ resourceErrors.get("file_name") }}</b-form-invalid-feedback
                          >
                        </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                        <div class="pt-2">
                          <label class="pt-4 form-label" for="feedback-code"
                          >Resource Type<sup class="text-danger">*</sup></label
                          >
                          <b-form-radio-group
                              @change="resourceErrors.clear('type')"
                              v-model="resource.type"
                              :class="resourceErrors.has('type') ? 'is-invalid' : ''"
                              id="feedback-product_code"

                              v-on:change="resourceType()"
                          >

                            <b-form-radio value="file">File</b-form-radio>
                            <b-form-radio value="url">Url</b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                              :state="!resourceErrors.has('type')"
                          >
                            {{ resourceErrors.get("type") }}</b-form-invalid-feedback
                          >
                        </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                        <div v-if="resource.type=='url'">
                          <label class="pt-4 form-label" for="feedback-name"
                          >Url<sup class="text-danger">*</sup></label
                          >
                          <b-form-input
                              @keydown="resourceErrors.clear('url')"
                              v-model="resource.url"
                              :class="resourceErrors.has('url') ? 'is-invalid' : ''"
                              id="feedback-name"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!resourceErrors.has('url')"
                          >
                            {{ resourceErrors.get("url") }}</b-form-invalid-feedback
                          >
                        </div>
                          </div>
                        </div>

                        <div v-if="resource.type=='file'">
                          <div class="row">
                          <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name"
                          >File<sup class="text-danger">*</sup><span v-b-tooltip.hover title="File Format Supported- Doc/Docx/Xls/Xlsx/Pdf/Rtf"><i class="fa fa-info-circle" aria-hidden="true"></i></span></label>
                          </div>
                          </div>
                          <div class="row">
                          <!-- Styled -->
                            <div class="col-md-6">
                          <b-form-file
                              v-model="resource.file"
                              :placeholder="resource.file==''?'Choose a file or drop it here...':resource.file"
                              drop-placeholder="Drop file here..."
                              accept=".doc, .docx, .xls, .xlsx, .pdf, .rtf"
                              ref="resourceFile"
                          ></b-form-file>
                        </div>
                            <div class="col-md-12">
                              <b-form-invalid-feedback
                                  :state="!resourceErrors.has('file')"
                              >
                                {{ resourceErrors.get("file") }}</b-form-invalid-feedback
                              >
                            </div>
                          </div>
                          </div>
                      </form>
                </b-modal>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import axios from "axios";
import Errors from "../../Errors";
import CustomAlert from "../CustomAlert";

export default {
  name:"cmsResource",
  components:{
    CustomAlert
  },
  data() {
    return {
      table:'',
      alertMessage: "",
      displayAlert: false,
      resource:{
        file_name:'',
        type:'',
        file:'',
        url:'',
        previewFile:''
      },
      editModal:{
        title:'Add Resources',
      },
      resourceErrors: new Errors(),
      formLoader:false
    }
  },
  methods:{

    addResource(){
      this.resourceErrors.clear();
      this.editModal.title = 'Add Resource';
      this.editModal.function = 'resourceCreate';
      this.clearResource();
      this.$root.$emit("bv::show::modal", "edit-modal");
    },
    resourceCreate() {
      this.formLoader = true;
      let formData = new FormData();
      formData.append('file_name', this.resource.file_name);
      formData.append('type', this.resource.type);
      if(this.resource.type=='url') {
        if(this.resource.url.substring(0, 4)=='http')
        formData.append('url', this.resource.url);
        else{
          let url='http://'+this.resource.url;
          formData.append('url', url);
        }
      }
      else
        formData.append('file', this.resource.file);

      axios.post(process.env.VUE_APP_URL + "api/resources/store", formData
          , {
            headers:this.adminHeaders
          }).then(response => {
        if (response.data.status == 'success') {
          this.$root.$emit("bv::hide::modal", "edit-modal");
          this.table.ajax.reload();
          this.formLoader = false;
          this.displayAlert = true;
          this.alertMessage = "Successfully Updated";
          console.log(response);
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.onFail(error.response.data.errors);
        this.formLoader = false;
      });
    },
    resourceEdit(){
      this.formLoader = true;
      let formData = new FormData();
      formData.append('file_name', this.resource.file_name);
      formData.append('type', this.resource.type);
      if(this.resource.type=='url') {
        if (this.resource.url.substring(0, 4) == 'http')
          formData.append('url', this.resource.url);
        else {
          let url = 'http://' + this.resource.url;
          formData.append('url', url);
        }
      }
      else
        formData.append('file', this.resource.file);

      axios.post(process.env.VUE_APP_URL + "api/resources/update/"+this.resource.id, formData
          , {
            headers:this.adminHeaders
          }).then(response => {
        if (response.data.status == 'success') {
          this.$root.$emit("bv::hide::modal", "edit-modal");
          this.table.ajax.reload();
          this.formLoader = false;
          this.displayAlert = true;
          this.alertMessage = "Successfully Updated";
          console.log(response);
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.formLoader = false;
        this.onFail(error.response.data.errors);
      });
    },
    handleOk(process){
      this[process]();
    },
    onFail(errors){
      this.resourceErrors.record(errors);
    },
    clearResource(){
      this.resource.file_name = '',
      this.resource.type = '',
      this.resource.file = '',
      this.resource.url = '',
      this.resource.previewFile = ''
    },
    resourceType(){
      this.resourceErrors.clear();
    }
  },
  mounted(){
    var self=this;
    this.$set(this.adminHeaders, 'Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    this.table = $("#resourceList").DataTable({
      dom: 'lfrtip',
      "bFilter": true,
      processing: true,
      serverSide: true,
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/resources/resourcesList",
        type: 'post',
        headers:this.adminHeaders
      },
      columns: [
        {data: 'id', name: 'id', searchable:false, visible: false},
        { data: "file_name", name: "file_name"},
        { data: 'type', name: 'type'},
        { data: null, orderable: false, searchable:false, render: (data) => {
            let btn = '';
            if(data.type!='URL')
              btn += `<a class="action-export export px-2" title="Download" data-file="${data.file}" data-type="${data.type}" href="javascript:void(0);" ><i class="fas fa-cloud-download-alt mw-20"></i></a> `
            else
              btn += `<a target="_blank" class="action-link px-2" title="External Link" href="${data.file}" ><i class="fas fa-link mw-20"></i></a> `

            btn += `<a class="action-editing edit px-2" title="Edit" data-id="${data.id}" href="javascript:void(0);" ><i class="fas fa-edit mw-20"></i></a> `
              btn += `<a class="action-delete faq_trash delete px-2" title="Delete" data-id="${data.id}" href="javascript:void(0);" ><i class="fas fa-trash mw-20"></i></a>`
            return btn
          }
        }
      ],
      order: [[0, "desc"]]
    });
    $(document).on("click", "table#resourceList tbody .edit" , function(e){
      self.showLoader = true;
      axios.get(self.basePath + 'api/resources/show/'+$(this).data('id')
          , {
            headers:self.adminHeaders
          }).then(response => {
        if (response.data.status == 'success') {
          self.resourceErrors.clear();
          self.editModal.function = 'resourceEdit';
          self.editModal.title = 'Edit Resource';
          self.resource.file_name = response.data.data.file_name;
          self.resource.type = response.data.data.type;
          if(self.resource.type=='url')
            self.resource.url = response.data.data.file;
          else {
            self.resource.file = response.data.data.file;
            self.resource.type = 'file';
          }
          self.resource.id = response.data.data.id;
          self.showLoader = false;
          self.$root.$emit("bv::show::modal", "edit-modal");
        } else {
          console.log(response);
        }
      }).catch(error => {
        self.showLoader = false;
        self.errors = error.response.data.errors || {};
      });
    }).on("click", "table#resourceList tbody .delete" , function(e){
      self.$bvModal.msgBoxConfirm("Are you sure about this ?" ,{
        title: 'Delete',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        centered: false
      }).then(value => {
        if (String(value) == "true") {
        e.preventDefault();
        self.showLoader = true;
        axios.delete(self.basePath + 'api/resources/delete/' + $(this).data('id')
            , {
              headers: self.adminHeaders
            }).then(response => {
          if (response.data.status == 'success') {
            self.showLoader = false;
            self.table.ajax.reload();
            self.displayAlert = true;
            self.alertMessage = "Successfully Deleted";
          } else {
            console.log(response);
          }
        }).catch(error => {
          self.showLoader = false;
          self.errors = error.response.data.errors || {};
        });
      }});
    }).on("click", "table#resourceList tbody .export" , function(e) {
      self.showLoader = true;
      axios({
        url: self.basePath + 'api/resources/download/'+$(this).data('file'),
        method: 'GET',
        responseType: 'arraybuffer',
        headers:self.adminHeaders
      }).then((response) => {
        self.showLoader = false;
        let blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = $(this).data('file')
        link.click()
      }).catch(error => {
        console.log('test',error);
        self.showLoader = false;

      });


    });
  }
}
</script>